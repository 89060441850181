<template>
	<div>
		<van-address-edit :area-list="areaList" show-delete show-set-default show-search-result :address-info="userAddressInfo"
			:search-result="searchResult" :area-columns-placeholder="['请选择', '请选择', '请选择']" @save="onSave"
			@delete="onDelete" />

	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	import area from '../../utils/area.js'
	import {
		addUserAddressList,
		getUserAddressDetail,
		delUserAddress
	} from '../../api/user.js'
	export default {
		data() {
			return {
				areaList: area,
				searchResult: [],
				enterPath:'',
				userAddressInfo:{}
			};
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.enterPath = from.path
			})
		},
		mounted() {
			
			if(this.$route.query.id){
				this.getAddressInfo()
			}
			
		},
		methods: {
		
			//获取地址详情
			getAddressInfo(){
				getUserAddressDetail({id:this.$route.query.id}).then((res)=>{
					if(res.data){
						this.userAddressInfo = res.data
					}
				})
			},
			//保存地址
			onSave(data) {
				var params = {}
				params.id = data.id
				params.county = data.areaCode
				params.name = data.name
				params.tel = data.tel
				params.is_default = data.isDefault
				params.detail = data.addressDetail

				addUserAddressList(params).then((res) => {
					console.log(res)
					if (res.code == 200) {
						let toast = this.$toast({
							message: "添加成功",
							type: "success"
						})
						setTimeout(() => {
							toast.clear()
							this.$router.go(-1)							

						}, 1000)

					}

				})
			},
			onDelete() {
				
				delUserAddress({id:this.userAddressInfo.id}).then((res)=>{
					console.log(res)
					if(res.code == 200){
						let toast = this.$toast({
							message: "删除成功",
							type: "success"
						})
						setTimeout(() => {
							toast.clear()
							this.$router.go(-1)							
						
						}, 1000)
						
					}
				})
			},
			onChangeDetail(val) {
				if (val) {
					this.searchResult = [{
						name: '黄龙万科中心',
						address: '杭州市西湖区',
					}, ];
				} else {
					this.searchResult = [];
				}
			}
		},
	};
</script>

<style>
</style>
